<template>
  <div v-if="curIndex > -1">
    <div
      class="image-viewer viewer-show"
      :class="hiddenTool ? 'toolbar-hide' : ''"
      @mousemove="handleMouseMove"
    >
      <!-- image-draggable image-dragging -->
      <div
        v-loading="!showPreview"
        class="image-viewer-wrapper"
        @click="hiddenPreview"
        @touchstart="touchstart"
        @touchmove="touchmove"
        @touchend="touchend"
      >
        <div
          v-if="showPreview"
          class="image-operation-container"
          :class="{
            'image-draggable': isTouch,
            'image-dragging': isTouch && isTouchStart,
          }"
          :style="imgStyle"
        >
          <img
            :src="cureentUrl"
            :width="imgStyle.width"
            :height="imgStyle.height"
            data-exportable="1"
            data-copyable="1"
            data-printable="1"
            draggable="false"
          />
        </div>
      </div>
      <div
        class="dx-btn-close-top-right"
        data-cursor-hide="hide"
        v-if="!isMobile"
        @click="hiddenPreview"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 10.94l5.788-5.789a.375.375 0 01.53 0l.53.53a.375.375 0 010 .53L13.062 12l5.788 5.788a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0L12 13.062l-5.788 5.788a.375.375 0 01-.53 0l-.53-.53a.375.375 0 010-.53L10.94 12 5.15 6.212a.375.375 0 010-.53l.53-.53a.375.375 0 01.531 0L12 10.938z"
            fill="#fff"
          ></path>
        </svg>
      </div>
      <div class="toolbox-container" v-if="!isMobile">
        <ul class="toolbox-list" data-cursor-hide="hide" @click="toolList">
          <li class="btn-item">
            <button
              type="button"
              :disabled="curIndex == 0 ? true : false"
              @click="prevImage"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M8.727 12l6.099 6.099a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0l-6.63-6.629a.75.75 0 010-1.06l6.63-6.63a.375.375 0 01.53 0l.53.53a.375.375 0 010 .531L8.727 12z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
          <li class="page-container">
            {{ curIndex + 1 }}/{{ previewSrcList.length }}
          </li>
          <li class="btn-item">
            <button
              type="button"
              :disabled="curIndex == previewSrcList.length - 1 ? true : false"
              @click="nextImage"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M15.209 12l-6.1-6.099a.375.375 0 010-.53l.531-.53a.375.375 0 01.53 0l6.63 6.629a.75.75 0 010 1.06l-6.63 6.63a.375.375 0 01-.53 0l-.53-.53a.375.375 0 010-.531L15.209 12z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
          <li class="divider"></li>
          <li class="btn-item">
            <button
              type="button"
              @click="zoomBtnIn"
              :disabled="transform.scale <= minScale ? true : false"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M17.057 15.996l2.981 2.982a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0l-2.982-2.981a7.5 7.5 0 111.06-1.06zM11.25 17.25a6 6 0 100-12 6 6 0 000 12zM8.625 10.5h5.25a.376.376 0 01.375.375v.75a.376.376 0 01-.375.375h-5.25a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
          <li class="percent-container">{{ scaleRate + "%" }}</li>
          <li class="btn-item">
            <button
              type="button"
              @click="zoomBtnOut"
              :disabled="transform.scale >= maxScale ? true : false"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M17.057 15.996l2.981 2.982a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0l-2.982-2.981a7.5 7.5 0 111.06-1.06zM10.5 10.5V8.625a.375.375 0 01.375-.375h.75a.375.375 0 01.375.375V10.5h1.875a.376.376 0 01.375.375v.75a.376.376 0 01-.375.375H12v1.875a.376.376 0 01-.375.375h-.75a.375.375 0 01-.375-.375V12H8.625a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375H10.5zm.75 6.75a6 6 0 100-12 6 6 0 000 12z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
          <li class="btn-item" v-if="isOriginRate">
            <button type="button" @click="setOriginRate">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19.675 5H4.825C4.369 5 4 5.356 4 5.794v11.912c0 .439.37.794.825.794h14.85c.456 0 .825-.355.825-.794V5.794c0-.438-.37-.794-.825-.794zM5.5 17V6.5H19V17H5.5zm6-4.5H13V14h-1.5v-1.5zm1.5-3h-1.5V11H13V9.5zm-5.72 1.198a.188.188 0 01-.28-.163v-.936c0-.135.073-.26.19-.327l.842-.474a.375.375 0 01.184-.048H9.25c.207 0 .375.168.375.375v5.25a.375.375 0 01-.375.375h-.757a.375.375 0 01-.375-.375v-4.154l-.838.477zm7.22-.163c0 .144.155.234.28.163l.838-.477v4.154c0 .207.168.375.375.375h.757a.375.375 0 00.375-.375v-5.25a.375.375 0 00-.375-.375h-1.034a.375.375 0 00-.184.048l-.841.474a.375.375 0 00-.191.327v.936z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
          <li class="btn-item" v-else>
            <button type="button" @click="setAutoRate">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M4.875 14.25a.375.375 0 01.375.375v2.625h2.625a.376.376 0 01.375.375v.75a.375.375 0 01-.375.375H4.5a.75.75 0 01-.75-.75v-3.375a.375.375 0 01.375-.375h.75zm15 0a.375.375 0 01.375.375V18a.75.75 0 01-.75.75h-3.375a.376.376 0 01-.375-.375v-.75a.376.376 0 01.375-.375h2.625v-2.625a.376.376 0 01.375-.375h.75zm-3.375-6a.75.75 0 01.75.75v6a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V9a.75.75 0 01.75-.75h9zm-.75 1.5h-7.5v4.5h7.5v-4.5zm-7.875-4.5a.375.375 0 01.375.375v.75a.375.375 0 01-.375.375H5.25v2.625a.375.375 0 01-.375.375h-.75a.375.375 0 01-.375-.375V6a.75.75 0 01.75-.75h3.375zm11.625 0a.75.75 0 01.75.75v3.375a.376.376 0 01-.375.375h-.75a.375.375 0 01-.375-.375V6.75h-2.625a.375.375 0 01-.375-.375v-.75a.376.376 0 01.375-.375H19.5z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
          <li class="divider"></li>
          <li class="btn-item">
            <button type="button" @click="turnImage">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12.375 6h2.626a4.5 4.5 0 014.5 4.5v.375a.376.376 0 01-.375.375h-.75a.376.376 0 01-.375-.375V10.5a3 3 0 00-3-3h-2.626v.75a.375.375 0 01-.6.3l-2-1.5a.375.375 0 010-.6l2-1.5a.375.375 0 01.6.3V6zM4.5 18.75v-7.875a.75.75 0 01.75-.75h9.375a.75.75 0 01.75.75v7.875a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75zM6 18h7.875v-6.375H6V18z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
          <li class="btn-item">
            <button type="button" @click="downFile">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18.75 16.875v1.875a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75v-1.875c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375V18h10.5v-1.125c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375zm-6-3.348l2.258-2.258a.375.375 0 01.53 0l.53.53a.375.375 0 010 .53l-3.712 3.713a.374.374 0 01-.53 0L8.114 12.33a.375.375 0 010-.53l.53-.531a.375.375 0 01.53 0l2.076 2.076V5.25c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375v8.277z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "perview-image",
  props: {
    previewSrcList: {
      type: Array,
      default() {
        return [];
      },
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
    curUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    const isMobile = Ua.isMob();
    return {
      isMobile,
      isTouchStart: false,
      hiddenTool: false,
      moveTimer: null,
      imgStyle: {
        transform: "",
        overflow: "hidden",
        opacity: 1,
        transition: "opacity 0.2s cubic-bezier(0.34, 0.69, 0.1, 1) 0s",
      },
      transform: {
        translate: ["-50%, -50%"],
        rotate: "0turn",
        scale: 1,
      },
      originStyle: {
        scale: 1,
      },
      autoStyle: {},
      showPreview: false,
      maxScale: 5,
      minScale: 0.1,
      isOriginRate: true,
      intakeOver: false,
      inIndex: 0,
      initDistance: null,
      initScale: null,
      lastScrollTime: 0,
      lastYOffset: 0,
    };
  },
  computed: {
    cureentUrl() {
      return this.previewSrcList[this.curIndex];
    },
    curIndex() {
      if (this.intakeOver) {
        return this.inIndex;
      }
      return this.previewSrcList.indexOf(this.curUrl);
    },
    scaleRate() {
      return Math.floor((this.transform.scale || 1) * 100);
    },
    isTouch() {
      let rotate = parseFloat(this.transform.rotate) / 0.25;
      let realW = parseInt(this.imgStyle.width) * this.transform.scale;
      let realH = parseInt(this.imgStyle.height) * this.transform.scale;
      if (rotate % 2 === 0) {
        // console.log('竖屏')
      } else {
        // console.log('横屏')
        let temp = realW;
        realW = realH;
        realH = temp;
      }
      return realW > window.innerWidth && realH > window.innerHeight;
    },
  },
  watch: {
    transform: {
      handler() {
        this.handleTransform();
      },
      deep: true,
    },
    cureentUrl: {
      handler() {
        if (this.cureentUrl) {
          this.init();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    downFile(){
     if (!this.cureentUrl) { return; }
      fetch(this.cureentUrl, {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Accept': 'image/jpeg,image/png,image/*',
        }
      })
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          let img = document.createElement('a');
          img.href = url; 
          img.download = `一堂DOC-${$.date.format(new Date(), 'yyyyMMddhhmmss')}.png`; 
          img.click();
          URL.revokeObjectURL(url); 
        })
        .catch(error => console.error('下载失败:', error));
    },
    init() {
      this.refresh();
      this.$nextTick(() => {
        const body = document.querySelector("body");
        if (body.append) {
          body.append(this.$el);
        } else {
          body.appendChild(this.$el);
        }
        this.loadImage();
      });
    },
    refresh() {
      this.showPreview = false;
      this.imgStyle = {
        transform: "",
        overflow: "hidden",
        opacity: 1,
        transition: "opacity 0.2s cubic-bezier(0.34, 0.69, 0.1, 1) 0s",
      };
      this.transform = {
        translate: ["-50%, -50%"],
        rotate: "0turn",
        scale: 1,
      };
      this.originStyle = {
        scale: 1,
      };
      this.autoStyle = {};
      this.isOriginRate = true;
    },
    loadImage() {
      let image = new Image();
      image.src = this.cureentUrl;
      let innerHeight = window.innerHeight;
      let innerWidth = window.innerWidth;
      image.onload = () => {
        let { width, height } = image;
        this.imgStyle.width = `${width}px`;
        this.imgStyle.height = `${height}px`;
        // this.transform.translate = ["-50%, -50%"];
        this.transform.rotate = "0turn";
        if (
          (width < innerWidth && height < innerHeight) ||
          (width < innerWidth && height == innerHeight) ||
          (width == innerWidth && height < innerHeight)
        ) {
          this.transform.scale = 1;
          this.autoStyle.scale = 1;
        } else if (width > innerWidth && height > innerHeight) {
          let scale = innerWidth / width - 0.1;
          if (height * scale > innerHeight) {
            let realH = parseInt(this.imgStyle.height) * scale;
            let lateY = (realH - window.innerHeight) / 2;
            this.transform.translate[1] = `${0}px, ${lateY}px`;
          } else {
            scale += 0.1;
          }
          this.transform.scale = scale;
          this.autoStyle.scale = scale;
        } else if (
          (width < innerWidth && height > innerHeight) ||
          (width == innerWidth && height > innerHeight)
        ) {
          let scale = width / innerWidth - 0.1;
          if (height * scale > innerHeight) {
            let realH = parseInt(this.imgStyle.height) * scale;
            let lateY = (realH - window.innerHeight) / 2;
            this.transform.translate[1] = `${0}px, ${lateY}px`;
          } else {
            scale += 0.1;
          }
          this.transform.scale = scale;
          this.autoStyle.scale = scale;
        } else if (
          (width > innerWidth && height < innerHeight) ||
          (width > innerWidth && height == innerHeight)
        ) {
          let scale = Math.min(innerWidth / width, height / innerHeight);
          this.transform.scale = scale;
          this.autoStyle.scale = scale;
        } else if (width == innerWidth && height == innerHeight) {
          this.transform.scale = 0.6;
          this.autoStyle.scale = 0.6;
        }
        this.previewShow();
      };
    },
    prevImage() {
      if (this.intakeOver) return --this.inIndex;
      this.inIndex = this.curIndex - 1;
      this.intakeOver = true;
    },
    nextImage() {
      if (this.intakeOver) return ++this.inIndex;
      this.inIndex = this.curIndex + 1;
      this.intakeOver = true;
    },
    handleTransform() {
      this.imgStyle.transform = "";
      for (let k in this.transform) {
        if (k === "translate") {
          let translate = this.transform[k];
          this.imgStyle.transform += translate.reduce((pre, next) => {
            return pre + `${k}(${next})`;
          }, "");
        } else {
          this.imgStyle.transform += `${k}(${this.transform[k]})`;
        }
      }
    },
    loopAddEvent() {
      this.moveTimer = setInterval(() => {
        this.hiddenTool = true;
      }, 3000);
    },
    handleMouseMove(event) {
      clearInterval(this.moveTimer);
      this.hiddenTool = false;
      this.loopAddEvent();
    },
    previewShow() {
      this.showPreview = true;
      this.stopAddUpNode();
    },
    hiddenPreview() {
      if (this.isTouching) return;
      this.removeUpNode();
      this.inIndex = -1;
      clearInterval(this.moveTimer);
      this.intakeOver = false;
      this.$emit("close");
    },
    stopAddUpNode() {
      document.addEventListener("mousewheel", this.handleEvent, {
        passive: false,
      });
      this.stopScrollNode();
    },
    removeUpNode() {
      this.moveScrollNode();
      document.removeEventListener("mousewheel", this.handleEvent, {
        passive: false,
      });
    },
    handleEvent(e) {
      e = e || window.event;
      // this.imgStyle.transition = "all 0.2s ease-in-out 0s";
      this.handleMouseMove();
      this.eventHalder(e);
      //   e.stopPropagation();
      e.preventDefault();
    },
    eventHalder(e) {
      if (e.ctrlKey) {
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - this.lastScrollTime;
        this.lastScrollTime = currentTime;

        const deltaY = event.deltaY;
        const speed = Math.abs(deltaY - this.lastYOffset) / timeDiff; // 计算滚动速度
        this.lastYOffset = deltaY;

        const factor = 0.25; // 调整缩放速率的基础值
        const adjustedFactor = -1 * factor * speed; // 根据速度调整缩放速率
        let scale = this.transform.scale;
        scale += deltaY * adjustedFactor;
        this.setScale(scale);
      } else {
        this.handleMoveDistance(
          e.deltaX,
          e.deltaY,
          e.wheelDeltaX,
          e.wheelDeltaY
        );
      }
    },
    handleMoveDistance(deltaX, deltaY, wheelDeltaX, wheelDeltaY) {
      let rotate = parseFloat(this.transform.rotate) / 0.25;
      let realW = parseInt(this.imgStyle.width) * this.transform.scale;
      let realH = parseInt(this.imgStyle.height) * this.transform.scale;
      if (rotate % 2 === 0) {
        // console.log('竖屏')
      } else {
        // console.log('横屏')
        let temp = realW;
        realW = realH;
        realH = temp;
      }
      if (realW > window.innerWidth || realH > window.innerHeight) {
        let translate = this.transform.translate[1];
        deltaX = Math.abs(deltaX);
        deltaY = Math.abs(deltaY);
        if (translate) {
          let [lateX, lateY] = translate.split(", ");
          lateX = parseFloat(lateX);
          lateY = parseFloat(lateY);
          if (wheelDeltaX > 0) {
            if ((realW - window.innerWidth) / 2 >= lateX + deltaX) {
              lateX += deltaX;
            } else {
              if (realW > window.innerWidth) {
                lateX = (realW - window.innerWidth) / 2;
              }
            }
          } else {
            if ((window.innerWidth - realW) / 2 <= lateX - deltaX) {
              lateX -= deltaX;
            } else {
              if (realW > window.innerWidth) {
                lateX = (window.innerWidth - realW) / 2;
              }
            }
          }
          if (wheelDeltaY > 0) {
            if ((realH - window.innerHeight) / 2 >= lateY + deltaY) {
              lateY += deltaY;
            } else {
              if (realH > window.innerHeight) {
                lateY = (realH - window.innerHeight) / 2;
              }
            }
          } else {
            if ((window.innerHeight - realH) / 2 <= lateY - deltaY) {
              lateY -= deltaY;
            } else {
              if (realH > window.innerHeight) {
                lateY = (window.innerHeight - realH) / 2;
              }
            }
          }
          this.transform.translate[1] = `${lateX}px, ${lateY}px`;
        } else {
          let lateY = deltaY;
          let lateX = deltaX;
          this.transform.translate[1] = `${lateX}px, ${lateY}px`;
        }
        this.handleTransform();
      }
    },
    mo(e) {
      e.preventDefault();
    },
    stopScrollNode() {
      //滑动限制
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", this.mo, { passive: false }); //禁止页面滑动
    },
    moveScrollNode() {
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", this.mo, { passive: false });
    },
    toolList() {
      this.handleMouseMove();
    },
    zoomBtnIn() {
      let scale = this.transform.scale / (1 + 0.15);
      scale = Math.max(scale, this.minScale);
      this.setTranslate(scale / this.transform.scale);
      this.transform.scale = scale;
    },
    zoomBtnOut() {
      let scale = this.transform.scale * (1 + 0.15);
      this.transform.scale = Math.min(scale, this.maxScale);
    },
    setTranslate(scale) {
      let translate = this.transform.translate[1];
      let realW = parseInt(this.imgStyle.width) * this.transform.scale;
      let realH = parseInt(this.imgStyle.height) * this.transform.scale;
      if (realW > window.innerWidth || realH > window.innerHeight) {
        if (translate) {
          let [lateX, lateY] = translate.split(", ");
          lateX = parseFloat(lateX);
          lateX *= scale - 0.1;
          this.transform.translate[1] = `${lateX}px, ${0}px`;
        }
      } else {
        this.transform.translate.length = 1;
      }
      this.handleTransform();
    },
    zoomIn(diff = 0.01) {
      let scale = this.transform.scale - diff;
      if (this.transform.scale <= this.minScale) {
        scale = this.minScale;
      }
      this.setTranslate(scale / this.transform.scale);
      this.transform.scale = Number(scale.toFixed(2));
    },
    zoomOut(diff = 0.01) {
      let scale = this.transform.scale + diff;
      if (this.transform.scale >= this.maxScale) {
        scale = this.maxScale;
      }
      this.transform.scale = Number(scale.toFixed(2));
    },
    setScale(scale) {
      scale = Math.max(Math.min(scale, this.maxScale), this.minScale);
      this.setTranslate(scale / this.transform.scale);
      this.transform.scale = Number(scale.toFixed(2));
    },
    setOriginRate() {
      this.isOriginRate = !this.isOriginRate;
      Object.assign(this.transform, this.originStyle);
    },
    setAutoRate() {
      this.isOriginRate = !this.isOriginRate;
      Object.assign(this.transform, this.autoStyle);
    },
    turnImage() {
      this.transform.translate.length = 1;
      let rotate = parseFloat(this.transform.rotate) - 0.25;
      this.transform.rotate = `${rotate}turn`;
    },
    touchstart(e) {
      this.isTouchStart = true;
      if (e.touches.length >= 2) {
        //判断是否有两个点在屏幕上
        this.isDoubleTouch = true;
        this.start = e.touches; //得到第一组两个点
        const touches = e.touches;
        var x1 = touches[0].clientX;
        var y1 = touches[0].clientY;
        var x2 = touches[1].clientX;
        var y2 = touches[1].clientY;
        this.initDistance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
        this.initScale = this.transform.scale;
      } else {
        this.firstT = Date.now();
        this.delta = Date.now() - this.now; //计算两次点击时间差
        this.now = Date.now();
        this.startPosition = [e.touches[0].pageX, e.touches[0].pageY];
        if (this.delta > 0 && this.delta <= 250) {
          //双击事件
        } else {
          //滑动事件
          this.isTouchEvent = true;
        }
      }
    },
    touchmove(e) {
      this.isTouching = true;
      if (e.touches.length >= 2 && this.isDoubleTouch) {
        //手势事件
        var now = e.touches; //得到第二组两个点
        const touches = e.touches;
        var x1 = touches[0].clientX;
        var y1 = touches[0].clientY;
        var x2 = touches[1].clientX;
        var y2 = touches[1].clientY;
        var distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
        var scale = (distance / this.initDistance) * this.initScale;
        this.setScale(scale);
      } else if (this.isTouchEvent) {
        this.movePosition = [e.touches[0].pageX, e.touches[0].pageY];
        this.endPosition = this.movePosition;
        this.movePosition = [
          this.movePosition[0] - this.startPosition[0],
          this.movePosition[1] - this.startPosition[1],
        ];
        this.startPosition = [e.touches[0].pageX, e.touches[0].pageY];
        this.handleMoveDistance(
          this.movePosition[0].toFixed(2),
          this.movePosition[1].toFixed(2),
          this.movePosition[0],
          this.movePosition[1]
        );
      }
    },
    touchend() {
      if (Date.now() - this.firstT < 300 && !this.isMobile) {
        this.isTouching = false;
        this.hiddenPreview();
      }
      this.initDistance = null;
      this.initScale = null;
      this.isDoubleTouch = false;
      this.isTouchEvent = false;
      this.isTouchStart = false;
      setTimeout(() => {
        this.isTouching = false;
      });
    },

    getDistance(p1, p2) {
      var x = p2.pageX - p1.pageX,
        y = p2.pageY - p1.pageY;
      return Math.sqrt(x * x + y * y);
    },
    getAngle(p1, p2) {
      var x = p1.pageX - p2.pageX,
        y = p1.pageY - p2.pageY;
      return (Math.atan2(y, x) * 180) / Math.PI;
    },

    getMidpoint(p1, p2) {
      var x = (p1.pageX + p2.pageX) / 2,
        y = (p1.pageY + p2.pageY) / 2;
      return [x, y];
    },
  },
};
</script>
<style>
.image-viewer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  cursor: zoom-out;
  z-index: 2000;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}
.image-viewer.viewer-show {
  background-color: rgba(0, 0, 0, 0.8);
}
.image-viewer .image-viewer-wrapper {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
}
.image-viewer-wrapper .el-loading-mask {
  background-color: transparent !important;
}
.image-viewer img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-user-select: none;
  user-select: none;
}
.image-viewer-wrapper img {
  opacity: 1;
  background-color: #fff;
}

.image-viewer .image-operation-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-user-select: none;
  user-select: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  outline: 1px solid transparent;
}
.image-viewer .image-operation-container img {
  position: unset;
  top: unset;
  left: unset;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: unset;
  transform: unset;
  -webkit-user-select: none;
  user-select: none;
}
.image-draggable {
  cursor: grab;
}
.image-dragging {
  cursor: grabbing;
  opacity: 1;
}
.dx-btn-close-top-right {
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  top: 20px;
  right: 20px;
  padding: 12px;
  cursor: pointer;
}
.image-viewer.toolbar-hide [data-cursor-hide="hide"] {
  opacity: 0;
}

.image-viewer.toolbar-hide * {
  cursor: none;
}
.toolbox-list > li.percent-container {
  width: 40px;
}
.dx-btn-close-top-right:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.toolbox-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toolbox-list {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 12px 16px;
  cursor: default;
  display: flex;
  flex-direction: row;
  height: 48px;
  margin: 0;
}
.toolbox-list > li {
  width: 24px;
  height: 24px;
  margin: 0 6px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  cursor: default;
  color: #fff;
  list-style: none;
}
.toolbox-list > li:first-child {
  margin-left: 0;
}
.toolbox-list > li.btn-item button[disabled="disabled"] {
  cursor: not-allowed;
}
.toolbox-list > li.btn-item button {
  cursor: pointer;
  border-radius: 4px;
}
body button {
  color: #1f2329;
}
.toolbox-list > li.btn-item button:disabled svg,
.toolbox-list > li.btn-item button:disabled svg path {
  fill: hsla(0, 0%, 100%, 0.4);
}
button svg:only-child {
  display: block;
}
svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.toolbox-list > li.page-container {
  width: auto;
}
.toolbox-list > li.divider {
  width: 1px;
  margin: 2px 10px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.3);
  cursor: default;
  height: 20px;
}
.toolbox-list > li.btn-item button:not(:disabled):hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}
</style>