var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.curIndex > -1
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "image-viewer viewer-show",
            class: _vm.hiddenTool ? "toolbar-hide" : "",
            on: { mousemove: _vm.handleMouseMove },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: !_vm.showPreview,
                    expression: "!showPreview",
                  },
                ],
                staticClass: "image-viewer-wrapper",
                on: {
                  click: _vm.hiddenPreview,
                  touchstart: _vm.touchstart,
                  touchmove: _vm.touchmove,
                  touchend: _vm.touchend,
                },
              },
              [
                _vm.showPreview
                  ? _c(
                      "div",
                      {
                        staticClass: "image-operation-container",
                        class: {
                          "image-draggable": _vm.isTouch,
                          "image-dragging": _vm.isTouch && _vm.isTouchStart,
                        },
                        style: _vm.imgStyle,
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.cureentUrl,
                            width: _vm.imgStyle.width,
                            height: _vm.imgStyle.height,
                            "data-exportable": "1",
                            "data-copyable": "1",
                            "data-printable": "1",
                            draggable: "false",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            !_vm.isMobile
              ? _c(
                  "div",
                  {
                    staticClass: "dx-btn-close-top-right",
                    attrs: { "data-cursor-hide": "hide" },
                    on: { click: _vm.hiddenPreview },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M12 10.94l5.788-5.789a.375.375 0 01.53 0l.53.53a.375.375 0 010 .53L13.062 12l5.788 5.788a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0L12 13.062l-5.788 5.788a.375.375 0 01-.53 0l-.53-.53a.375.375 0 010-.53L10.94 12 5.15 6.212a.375.375 0 010-.53l.53-.53a.375.375 0 01.531 0L12 10.938z",
                            fill: "#fff",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isMobile
              ? _c("div", { staticClass: "toolbox-container" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "toolbox-list",
                      attrs: { "data-cursor-hide": "hide" },
                      on: { click: _vm.toolList },
                    },
                    [
                      _c("li", { staticClass: "btn-item" }, [
                        _c(
                          "button",
                          {
                            attrs: {
                              type: "button",
                              disabled: _vm.curIndex == 0 ? true : false,
                            },
                            on: { click: _vm.prevImage },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M8.727 12l6.099 6.099a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0l-6.63-6.629a.75.75 0 010-1.06l6.63-6.63a.375.375 0 01.53 0l.53.53a.375.375 0 010 .531L8.727 12z",
                                    fill: "#fff",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "page-container" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.curIndex + 1) +
                            "/" +
                            _vm._s(_vm.previewSrcList.length) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "btn-item" }, [
                        _c(
                          "button",
                          {
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.curIndex == _vm.previewSrcList.length - 1
                                  ? true
                                  : false,
                            },
                            on: { click: _vm.nextImage },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M15.209 12l-6.1-6.099a.375.375 0 010-.53l.531-.53a.375.375 0 01.53 0l6.63 6.629a.75.75 0 010 1.06l-6.63 6.63a.375.375 0 01-.53 0l-.53-.53a.375.375 0 010-.531L15.209 12z",
                                    fill: "#fff",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "divider" }),
                      _vm._v(" "),
                      _c("li", { staticClass: "btn-item" }, [
                        _c(
                          "button",
                          {
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.transform.scale <= _vm.minScale
                                  ? true
                                  : false,
                            },
                            on: { click: _vm.zoomBtnIn },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M17.057 15.996l2.981 2.982a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0l-2.982-2.981a7.5 7.5 0 111.06-1.06zM11.25 17.25a6 6 0 100-12 6 6 0 000 12zM8.625 10.5h5.25a.376.376 0 01.375.375v.75a.376.376 0 01-.375.375h-5.25a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375z",
                                    fill: "#fff",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "percent-container" }, [
                        _vm._v(_vm._s(_vm.scaleRate + "%")),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "btn-item" }, [
                        _c(
                          "button",
                          {
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.transform.scale >= _vm.maxScale
                                  ? true
                                  : false,
                            },
                            on: { click: _vm.zoomBtnOut },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M17.057 15.996l2.981 2.982a.375.375 0 010 .53l-.53.53a.375.375 0 01-.53 0l-2.982-2.981a7.5 7.5 0 111.06-1.06zM10.5 10.5V8.625a.375.375 0 01.375-.375h.75a.375.375 0 01.375.375V10.5h1.875a.376.376 0 01.375.375v.75a.376.376 0 01-.375.375H12v1.875a.376.376 0 01-.375.375h-.75a.375.375 0 01-.375-.375V12H8.625a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375H10.5zm.75 6.75a6 6 0 100-12 6 6 0 000 12z",
                                    fill: "#fff",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isOriginRate
                        ? _c("li", { staticClass: "btn-item" }, [
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: { click: _vm.setOriginRate },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M19.675 5H4.825C4.369 5 4 5.356 4 5.794v11.912c0 .439.37.794.825.794h14.85c.456 0 .825-.355.825-.794V5.794c0-.438-.37-.794-.825-.794zM5.5 17V6.5H19V17H5.5zm6-4.5H13V14h-1.5v-1.5zm1.5-3h-1.5V11H13V9.5zm-5.72 1.198a.188.188 0 01-.28-.163v-.936c0-.135.073-.26.19-.327l.842-.474a.375.375 0 01.184-.048H9.25c.207 0 .375.168.375.375v5.25a.375.375 0 01-.375.375h-.757a.375.375 0 01-.375-.375v-4.154l-.838.477zm7.22-.163c0 .144.155.234.28.163l.838-.477v4.154c0 .207.168.375.375.375h.757a.375.375 0 00.375-.375v-5.25a.375.375 0 00-.375-.375h-1.034a.375.375 0 00-.184.048l-.841.474a.375.375 0 00-.191.327v.936z",
                                        fill: "#fff",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _c("li", { staticClass: "btn-item" }, [
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: { click: _vm.setAutoRate },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M4.875 14.25a.375.375 0 01.375.375v2.625h2.625a.376.376 0 01.375.375v.75a.375.375 0 01-.375.375H4.5a.75.75 0 01-.75-.75v-3.375a.375.375 0 01.375-.375h.75zm15 0a.375.375 0 01.375.375V18a.75.75 0 01-.75.75h-3.375a.376.376 0 01-.375-.375v-.75a.376.376 0 01.375-.375h2.625v-2.625a.376.376 0 01.375-.375h.75zm-3.375-6a.75.75 0 01.75.75v6a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V9a.75.75 0 01.75-.75h9zm-.75 1.5h-7.5v4.5h7.5v-4.5zm-7.875-4.5a.375.375 0 01.375.375v.75a.375.375 0 01-.375.375H5.25v2.625a.375.375 0 01-.375.375h-.75a.375.375 0 01-.375-.375V6a.75.75 0 01.75-.75h3.375zm11.625 0a.75.75 0 01.75.75v3.375a.376.376 0 01-.375.375h-.75a.375.375 0 01-.375-.375V6.75h-2.625a.375.375 0 01-.375-.375v-.75a.376.376 0 01.375-.375H19.5z",
                                        fill: "#fff",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "divider" }),
                      _vm._v(" "),
                      _c("li", { staticClass: "btn-item" }, [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: { click: _vm.turnImage },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M12.375 6h2.626a4.5 4.5 0 014.5 4.5v.375a.376.376 0 01-.375.375h-.75a.376.376 0 01-.375-.375V10.5a3 3 0 00-3-3h-2.626v.75a.375.375 0 01-.6.3l-2-1.5a.375.375 0 010-.6l2-1.5a.375.375 0 01.6.3V6zM4.5 18.75v-7.875a.75.75 0 01.75-.75h9.375a.75.75 0 01.75.75v7.875a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75zM6 18h7.875v-6.375H6V18z",
                                    fill: "#fff",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "btn-item" }, [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: { click: _vm.downFile },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M18.75 16.875v1.875a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75v-1.875c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375V18h10.5v-1.125c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375zm-6-3.348l2.258-2.258a.375.375 0 01.53 0l.53.53a.375.375 0 010 .53l-3.712 3.713a.374.374 0 01-.53 0L8.114 12.33a.375.375 0 010-.53l.53-.531a.375.375 0 01.53 0l2.076 2.076V5.25c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375v8.277z",
                                    fill: "#fff",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }